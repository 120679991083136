import {
  GoogleAuthProvider,
  getAuth,
  onAuthStateChanged,
  signInWithPopup,
  signOut
} from 'firebase/auth'
import '../firebase'
import { createContext, useContext, useState, useEffect, useMemo } from 'react'

const AuthContext = createContext()

export const useAuth = () => {
  const context = useContext(AuthContext)
  if (context === null) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}

export const AuthProvider = ({children}) => {
  const [loading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState(null)

  useEffect(() => {
    const auth = getAuth()
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user)
      setLoading(false)
    })

    return unsubscribe
  }, [])

  const signInWithGoogle = async () => {
    const auth = getAuth()
    const provider = new GoogleAuthProvider()
    const res = await signInWithPopup(auth, provider)
    console.log(res)
  }

  const logout = () => {
    const auth = getAuth()
    return signOut(auth)
  }

  const memoVal = useMemo(
    () => ({
      user: currentUser,
      signInWithGoogle,
      logout
    }),
    [currentUser]
  )

  return (
    <AuthContext.Provider value={memoVal}>
      {!loading && children}
    </AuthContext.Provider>
  )
}
