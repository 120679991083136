import React, { useState } from 'react'
import { useAuth } from './AuthContext'
import { useNavigate } from 'react-router-dom'
import SignInGoogleButton from '../Components/SignInGoogleButton'
import { Vortex } from 'react-loader-spinner'
import toast from 'react-hot-toast'
const AuthComponent = () => {
  const { user, logout } = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  function handleLogOut () {
    try {
      setLoading(true)
      logout()
      toast.success('Logout Successful')
      navigate('/')
    } catch (error) {
      toast.error('Try Again!!')
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      {loading && (
        <Vortex
          visible={true}
          height='80'
          width='100%'
          ariaLabel='vortex-loading'
          wrapperStyle={{ width: '100%' }}
          wrapperClass='vortex-wrapper'
          colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
        />
      )}
      {user !== null ? (
        <>
          <button
            style={{
              fontSize: '.9em',
              background: 'green',
              fontFamily: 'cursive',
              padding: '10px'
            }}
            onClick={handleLogOut}
          >
            {user?.displayName}, Logout
          </button>
        </>
      ) : (
        <>
          <SignInGoogleButton title='Sign In With Google' />
        </>
      )}
    </>
  )
}

export default AuthComponent
