import axios from "axios";

const instance = axios.create({
  baseURL: "https://vast-teal-mussel-slip.cyclic.app",
  // baseURL: "http://localhost:8080",
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("access_token");
  config.headers = { Authorization: `Bearer ${token}` };
  return config;
});

export default instance;
