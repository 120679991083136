import React, { useState } from 'react'
import { useAuth } from '../context/AuthContext'
import toast from 'react-hot-toast'
import { Vortex } from 'react-loader-spinner'
const SignInGoogleButton = ({ title }) => {
  const { signInWithGoogle } = useAuth()
  const [loading, setLoading] = useState(false)
  const handleSubmit = async () => {
    try {
      setLoading(true)
      await signInWithGoogle()
    } catch (error) {
      console.log(error)
      toast.error('Try Again!')
    } finally {
      setLoading(true)
    }
  }

  return (
    <button
      className='flex w-[25%] items-center justify-between rounded-full font-julius border-2 border-amber-500 bg-black  text-[0.2rem] font-bold uppercase tracking-wider text-white transition-all duration-300 ease-in-out hover:bg-gray-200 dark:bg-black dark:text-white dark:hover:bg-white shadow-lg'
      style={{
        fontSize: '.9em',
        padding: '10px',
        fontFamily: 'cursive',
      }}
      type='button'
      onClick={handleSubmit}
    >
      {loading && (
        <Vortex
          visible={true}
          height='10'
          ariaLabel='vortex-loading'
          wrapperStyle={{ width: '100%' }}
          wrapperClass='vortex-wrapper'
          colors={['red', 'green', 'blue', 'yellow', 'orange', 'purple']}
        />
      )}
      <img
        alt=''
        className='h-3 w-'
        src='https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg'
      />

      {title}
    </button>
  )
}

export default SignInGoogleButton
